<template>
  <div>
    <!-- <a href="/viracloudmigrate">transfer VPCs</a> -->
    <!-- <vs-button @click="getVPClist">test</vs-button> -->
    <vx-card class="my-5">
      <vs-divider>Filter by</vs-divider>
      <div class="flex justify-between w-full">
        <vs-input
          v-model="vpc_id"
          class="w-1/6"
          label-placeholder="VPC id"
          @keyup.enter="getVPClist()"
        ></vs-input>
        <vs-input
          v-model="user_id"
          class="w-1/6"
          label-placeholder="user ID"
          @keyup.enter="getVPClist()"
        ></vs-input>
        <vs-input
          v-model="vpc_name"
          class="w-1/6"
          label-placeholder="VPC Name"
          @keyup.enter="getVPClist()"
        ></vs-input>
        <vs-input
          v-model="vpc_number"
          class="w-1/6"
          label-placeholder="VPC number"
          @keyup.enter="getVPClist()"
        ></vs-input>
        <vs-input
          v-model="vlan_id"
          class="w-1/6"
          label-placeholder="Vlan ID"
          @keyup.enter="getVPClist()"
        ></vs-input>
        <vs-button
          class="my-5 w-1/6"
          size="large"
          @click="getVPClist()"
          icon="search"
        >
        </vs-button>
      </div>
    </vx-card>
    <vs-button
      type="filled"
      color="primary"
      class="w-full"
      @click="transferCard = !transferCard"
      >Transfer VPC to user</vs-button
    >
    <vx-card class="my-3" v-if="transferCard">
      <div class="flex mar justify-center">
        <p class="my-3">انتقال</p>
        <vs-input
          type="number"
          class="my-1 mx-5"
          label-placeholder="شماره جزیره"
          v-model="VPCnum"
        ></vs-input>
        <p class="my-3">به کاربر</p>
        <vs-input
          type="number"
          class="my-1 mx-5"
          label-placeholder="کاربر ID"
          v-model="UserID"
        ></vs-input>
      </div>
      <vs-button
        v-if="VPCnum && UserID"
        color="warning"
        type="border"
        class="w-full my-8"
        @click="TransferVPC(VPCnum, UserID)"
        >انتقال</vs-button
      >
    </vx-card>
    <vs-button
      type="filled"
      color="primary"
      class="w-full my-5"
      to="/viracloudmigrate"
      >Transfer VPC to Node</vs-button
    >
    <vs-divider class="my-5 mar flex justify-center">VPC list</vs-divider>
    <!-- <p>{{VPCdata}}</p> -->
    <vx-card>
      <vs-pagination
        class="my-3"
        :total="page_count"
        v-model="currentpage"
      ></vs-pagination>
      <vs-table
        @dblSelection="doubleSelection"
        v-model="selected"
        :data="VPCdata"
      >
        <template slot="thead">
          <vs-th> VPC ID </vs-th>
          <vs-th> User ID </vs-th>
          <vs-th> Is suspend </vs-th>
          <vs-th> Suspended at </vs-th>
          <vs-th> Created at </vs-th>
          <vs-th> Total public IP </vs-th>
          <vs-th> Total VM </vs-th>
          <vs-th> Vlan ID </vs-th>
          <vs-th> VPC hostname </vs-th>
          <vs-th> VPC name </vs-th>
          <vs-th> VPC number </vs-th>
          <vs-th> VPC status </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].VPC_ID">
              {{ data[indextr].id }}
            </vs-td>
            <vs-td :data="data[indextr].User_ID">
              {{ data[indextr].user_id }}
            </vs-td>
            <vs-td
              :class="data[indextr].is_suspend ? 'text-danger' : 'text-success'"
              :data="data[indextr].Is_suspend"
            >
              {{ data[indextr].is_suspend ? "Suspended" : "Normal" }}
            </vs-td>
            <vs-td :data="data[indextr].Suspended_at">
              {{
                data[indextr].suspended_at ? data[indextr].suspended_at : "null"
              }}
            </vs-td>
            <vs-td :data="data[indextr].Created_at">
              {{ data[indextr].created_at }}
            </vs-td>
            <vs-td :data="data[indextr].Total_public_IP">
              {{ data[indextr].total_public_ip }}
            </vs-td>
            <vs-td :data="data[indextr].Total_VM">
              {{ data[indextr].total_vm }}
            </vs-td>
            <vs-td :data="data[indextr].Vlan_ID">
              {{ data[indextr].vlan_id }}
            </vs-td>
            <vs-td :data="data[indextr].VPC_hostname">
              {{
                data[indextr].vpc_hostname
                  ? `Name:${data[indextr].vpc_hostname}`
                  : "null"
              }}
            </vs-td>
            <vs-td :data="data[indextr].VPC_name">
              {{ data[indextr].vpc_name }}
            </vs-td>
            <vs-td :data="data[indextr].VPC_number">
              {{ data[indextr].vpc_number }}
            </vs-td>
            <vs-td :data="data[indextr].VPC_status">
              {{ data[indextr].vpc_status ? "Active" : "Inactive" }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        class="my-3"
        :total="page_count"
        v-model="currentpage"
      ></vs-pagination>
      <!-- <p>{{ selected }}</p> -->
      <vue-json-pretty :data="selected"></vue-json-pretty>
    </vx-card>
  </div>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      page_count: 0,
      currentpage: 1,
      vpc_id: "",
      user_id: "",
      vpc_number: "",
      vpc_name: "",
      is_suspend: "",
      vlan_id: "",
      VPCdata: [],
      selected: null,
      transferCard: false,
      VPCnum: "",
      UserID: "",
    };
  },
  watch: {
    currentpage() {
      this.getVPClist({ page: this.currentpage });
    },
  },
  methods: {
    doubleSelection() {
      this.$router.push(`/user?id=${this.selected.user_id}`);
    },
    TransferVPC(VPCnum, UID) {
      this.$http
        .post("/admin/vira/cloud/vpc/move", {
          vpc_number: VPCnum,
          target_user_id: UID,
        })
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    getVPClist() {
      const data = {
        vpc_id: this.vpc_id,
        user_id: this.user_id,
        vpc_number: this.vpc_number,
        vpc_name: this.vpc_name,
        is_suspend: this.is_suspend,
        vlan_id: this.vlan_id,
        page: this.currentpage,
      };
      // console.log(data);
      this.VPCdata = [];
      this.$vs.loading();
      this.$http
        .post("/admin/vira/cloud/vpc/list", data)
        .then((res) => {
          this.$vs.loading.close();
          this.page_count = res.data.params.last_page;
          this.VPCdata = res.data.params.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
  },
  created() {
    this.getVPClist();
  },
};
</script>
